import { GeographicState } from '../enums/states';

const replacementStates = [
    GeographicState.AK,
    GeographicState.FL,
    GeographicState.HI,
    GeographicState.ID,
    GeographicState.IN,
    GeographicState.MD,
    GeographicState.MI,
    GeographicState.MN,
    GeographicState.MT,
    GeographicState.NV,
    GeographicState.NH,
    GeographicState.NJ,
    GeographicState.OR,
    GeographicState.SD,
    GeographicState.TX,
    GeographicState.WA,
    GeographicState.WI,
    GeographicState.WV
];

export default replacementStates;
